import { useEffect, useReducer, useCallback, useMemo, useState } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
import Keycloak from 'keycloak-js';
//
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from './auth-context';
import { setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../types';
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [profileComplete, setProfileComplete] = useState(true);
  const [onBoardingComplete, setOnBoardingComplete] = useState(true);
  const initOptions = useMemo(
    () => ({
      url: process.env.REACT_APP_KC_DOMAIN ?? '',
      realm: process.env.REACT_APP_KC_REALM ?? '',
      clientId: process.env.REACT_APP_KC_CLIENT_ID ?? '',
    }),
    []
  );

  const kc = useMemo(() => new Keycloak(initOptions), [initOptions]);
  const initialize = useCallback(async () => {
    try {
      const auth = await kc.init({
        onLoad: 'check-sso', // Supported values: 'check-sso' , 'login-required'
        checkLoginIframe: false,
        pkceMethod: 'S256',
        // redirectUri: window.location.origin,
      });

      if (auth) {
        setSession(kc?.token ?? '');
        const userData = jwtDecode(kc?.token ?? '');
        // @ts-ignore
        const role: 'aggregator' | 'distributor' = userData?.realm_access?.roles?.includes(
          'distributor'
        )
          ? 'distributor'
          : 'aggregator';
        // @ts-ignore
        const id = userData?.['sinch-user-id'] ?? '';
        const userRes = await axios.get(`${endpoints.auth.me(role)}?${role}_id=${id}`);

        const data = userRes?.data?.data ?? {};
        const res = {
          data: {
            accessToken: kc?.token,
            user: {
              id,
              displayName: data.name,
              photoURL:
                'https://media.licdn.com/dms/image/C4D03AQFg4KQ8ULsqbQ/profile-displayphoto-shrink_200_200/0/1652253977711?e=1709164800&v=beta&t=j8IQtP3peuRqwEYrRMxplfqkKJYmDZRJljHvhhcP3mQ',
              role,
              isPublic: true,
              ondc_store_enabled: true,
              ...data,
            },
          },
        };

        const { user } = res.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
              accessToken: kc?.token,
            },
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, [kc]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    // const data = {
    //   email,
    //   password,
    // };
    // const res = {
    //   accessToken:
    //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4ODY0YzcxNy01ODdkLTQ3MmEtOTI5YS04ZTVmMjk4MDI0ZGEtMCIsImlhdCI6MTcwMzU3MzI1NywiZXhwIjoxNzAzODMyNDU3fQ.d4XfH8ONe8V6h9INj-faTRMb23ArkZO8pXb4SHwno4s',
    //   user: {
    //     id: '8864c717-587d-472a-929a-8e5f298024da',
    //     displayName: 'Jaydon Frankie',
    //     email,
    //     password,
    //     photoURL:
    //       'https://media.licdn.com/dms/image/C4D03AQFg4KQ8ULsqbQ/profile-displayphoto-shrink_200_200/0/1652253977711?e=1709164800&v=beta&t=j8IQtP3peuRqwEYrRMxplfqkKJYmDZRJljHvhhcP3mQ',
    //     phoneNumber: '+40 777666555',
    //     country: 'United States',
    //     address: '90210 Broadway Blvd',
    //     state: 'California',
    //     city: 'San Francisco',
    //     zipCode: '94116',
    //     about:
    //       'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
    //     role: email === 'aggregator@lumegalabs.com' ? 'aggregator' : 'distributor',
    //     isPublic: true,
    //   },
    // };
    // const { accessToken, user } = res;
    // setSession(accessToken);
    // dispatch({
    //   type: Types.LOGIN,
    //   payload: {
    //     user: {
    //       ...user,
    //       accessToken,
    //     },
    //   },
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const kcLogin = useCallback(async () => {
    if (kc?.authenticated) {
      window.location.reload();
    }
    try {
      window.location.replace(kc?.createLoginUrl() ?? '');
    } catch (e) {
      console.error(e);
    }
  }, [kc]);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const res = await axios.post(endpoints.auth.register, data);

      const { accessToken, user } = res.data;

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user: {
            ...user,
            accessToken,
          },
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    try {
      if (kc?.token) {
        await kc?.logout({ redirectUri: process.env.REACT_APP_CLIENT });
      }
      setSession(null);
      dispatch({ type: Types.LOGOUT });
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, [kc]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      kcLogin,
      login,
      register,
      logout,
      profileComplete,
      onBoardingComplete,
    }),
    [login, logout, register, state.user, status, kcLogin, onBoardingComplete, profileComplete]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
